/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect, useState } from "react";

export default function usePermissionChecker(codeName: string): boolean {
  const [hasPermission, setHasPermission] = useState(false);
  const isSuperUser = JSON.parse(localStorage.getItem("user" || "{}"))?.is_superuser;

  useEffect(() => {
    const allPermissions = JSON.parse(localStorage.getItem("user" || "{}"))?.groups?.[0]?.permissions?.map((permission: any) => permission.codename) ?? [];
    
    if (allPermissions) {
      try {
        if (
          Array.isArray(allPermissions) &&
          allPermissions.includes(codeName)
        ) {
          setHasPermission(true);
        }
      } catch (error) {
        console.error("Invalid permissions format in localStorage", error);
      }
    }
  }, [codeName]);

  if (isSuperUser) {
    return true;
  }

  return hasPermission;
}
