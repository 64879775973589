/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import Container from "./views/container";
import Details from "./views/details";

const FolderIcon = lazy(() => import("./components/folder-icon"));
const Forms = lazy(() => import("./views"));

const formsRoutes = {
  id: "tablet-forms",
  title: "tablet-forms",
  path: "tablet-forms",
  element: <Container of={<Forms />} />,
  Icon: FolderIcon,
  children: [
    {
      id: "tablet-form-details",
      title: "tablet-form-details",
      path: ":id",
      element: <Details />,
    },
  ],
};

export default formsRoutes;
