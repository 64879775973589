/* eslint-disable no-param-reassign */
import axios from "axios";
import type { AxiosError } from "axios";
import settings from "@/config/settings";
import { refreshToken } from "@/features/auth/api";
import type { AppLang } from "@/types";

const baseUrl = (lang: string | null): string => {
  if (lang === "uzLatin") {
    return `${settings.baseURL}/uz/api/v1`;
  }
  if (lang === "uzCryllic") {
    return `${settings.baseURL}/ur/api/v1`;
  }
  return `${settings.baseURL}/ru/api/v1`;
};

const acceptLangMap: Record<AppLang, string> = {
  uzLatin: "uz",
  uzCryllic: "ur",
  ru: "ru",
};

const requestWithoutData = axios.create({
  // baseURL: settings.baseURL,
  timeout: settings.requestTimeout,
});

requestWithoutData.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  const lang = localStorage.getItem("i18nextLng") as AppLang | null;

  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.baseURL = baseUrl(lang);
  // config.validateStatus = (status) => status < 500;

  config.headers["Accept-Language"] = acceptLangMap[lang ?? "ru"];

  return config;
}, errorHandler);

requestWithoutData.interceptors.response.use(
  (response) => response,
  errorHandler,
);

export async function errorHandler(error: AxiosError): Promise<void> {
  if (error.response !== null) {
    // server responded with a status code that falls out of the range of 2xx
    if (error.response?.status === 401) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const refresh_token = localStorage.getItem("refresh_token");

      if (refresh_token !== null) {
        console.log('wwwwwwww');
        try {
          const res = await refreshToken({ refresh: refresh_token });
          const { refresh, access } = res.data;
          localStorage.setItem("refresh_token", refresh);
          localStorage.setItem("access_token", access);
          window.location.reload();
        } catch (err) {
          localStorage.setItem("refresh_token_error", JSON.stringify(err));
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          window.location.replace("/auth?session_expired=1"); // /auth/login
        }
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        window.location.replace("/auth?session_expired=1");
      }
    }

    await Promise.reject(error.response);
  }

  if (error.request !== null) {
    await Promise.reject(error.request);
  }

  await Promise.reject(error);
}

export async function fetchBlob(
  url: string,
  params = {},
): Promise<{ blob: Blob; headers: Record<string, string> }> {
  return await requestWithoutData
    .get(url, {
      responseType: "blob",
      params,
    })
    .then((response) => ({
        blob: response.data,
        headers: response.headers,
      }))
    .catch((error) => {
      console.error("Error fetching blob:", error);
      throw error;
    });
}

export default requestWithoutData;
