import formatAmount from "@/helpers/format-amount";
import { Divider } from "antd";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

export default function ReturnedWorks({ data }: any): JSX.Element {
  const { t } = useTranslation();
  console.log("eeeeeeee", data);

  const mvdDonutChart = {
    chart: {
      type: "pie",
      height: "100%",
    },
    labels: [...(data?.mvd?.map((item: any) => item.name) ?? [])],
    series: [...(data?.mvd?.map((item: any) => item.value) ?? [])],
    colors: ["#37CD7B", "#FF5858", "#FFE071"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val: any) {
          return val.toLocaleString();
        },
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const value = series[seriesIndex];
        const label = w.globals.labels[seriesIndex];
        return `<div class="custom-tooltip">${label}: ${formatAmount(
          value,
        )}</div>`;
      },
    },
    responsive: [
      {
        options: {
          chart: {
            width: 100,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "70%",
          labels: {
            show: false,
            total: {
              show: false,
              label: "Total",
              // formatter: function () {
              //   return 20000;
              // },
            },
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "14px",
              color: undefined,
              offsetY: 5,
              formatter: function (val: any) {
                return val.toLocaleString();
              },
            },
          },
        },
      },
    },
  };

  const procuraturaDonutChart = {
    chart: {
      type: "pie",
      height: "100%",
    },
    labels: [...(data?.prokuratura?.map((item: any) => item.name) ?? [])],
    series: [...(data?.prokuratura?.map((item: any) => item.value) ?? [])],
    colors: ["#37CD7B", "#FF5858", "#FFE071"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val: any) {
          return val.toLocaleString();
        },
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const value = series[seriesIndex];
        const label = w.globals.labels[seriesIndex];
        return `<div class="custom-tooltip">${label}: ${formatAmount(
          value,
        )}</div>`;
      },
    },
    responsive: [
      {
        options: {
          chart: {
            width: 100,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "70%",
          labels: {
            show: false,
            total: {
              show: false,
              label: "Total",
              // formatter: function () {
              //   return 20000;
              // },
            },
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "14px",
              color: undefined,
              offsetY: 5,
              formatter: function (val: any) {
                return val.toLocaleString();
              },
            },
          },
        },
      },
    },
  };

  return (
    <div className="flex flex-col w-full p-4 overflow-hidden h-full">
      <div className="flex items-start justify-center w-full h-[22px] overflow-hidden">
        <h4 className="text-left text-[14px] font-semibold font-Golos text-[#232E3A]">
          {t("returned-works")}
        </h4>
      </div>

      <div className="flex flex-col h-[calc(100%-22px)] max-h-[calc(100%-22px)] min-h-[calc(100%-22px)] justify-between overflow-hidden">
        <div className="flex items-center h-[calc(50%-12px)] max-h-[calc(50%-12px)] min-h-[calc(50%-12px)] gap-0">
          <div className="h-full w-max">
            <ReactApexChart
              options={
                {
                  ...mvdDonutChart,
                  labels: mvdDonutChart.labels,
                } as any
              }
              series={mvdDonutChart.series}
              type="donut"
              width="100px"
              height="100%"
            />
          </div>

          <div className="flex flex-col gap-[6px] w-full">
            {data?.mvd?.map((item: any, index: number) => (
              <div
                className="flex items-center justify-between"
                key={item?.name}
              >
                <div className="flex items-center gap-2">
                  <span
                    style={{
                      borderColor: ["#37CD7B", "#FF5858", "#FFE071"]?.[index],
                    }}
                    className="inline-block w-[8px] h-[8px] border-2 rounded-full "
                  />
                  <p className="text-xs font-normal text-[#232E3A] line-clamp-1">
                    {item?.name}
                  </p>
                </div>
                <p className="text-xs font-medium text-[#9747FF]">
                  {formatAmount(item?.value)}
                </p>
              </div>
            ))}
          </div>
        </div>

        <Divider className="m-0 p-0" />

        <div className="flex items-center h-[calc(50%-12px)] max-h-[calc(50%-12px)] min-h-[calc(50%-12px)] gap-0">
          <div className="h-full">
            <ReactApexChart
              options={
                {
                  ...procuraturaDonutChart,
                  labels: procuraturaDonutChart.labels,
                } as any
              }
              series={procuraturaDonutChart.series}
              type="donut"
              width="100px"
              height="100%"
            />
          </div>

          <div className="flex flex-col gap-[6px] w-full">
            {data?.prokuratura?.map((item: any, index: number) => (
              <div
                className="flex items-center justify-between"
                key={item?.name}
              >
                <div className="flex items-center gap-2">
                  <span
                    style={{
                      borderColor: ["#37CD7B", "#FF5858", "#FFE071"]?.[index],
                    }}
                    className="inline-block w-[8px] h-[8px] border-2 rounded-full "
                  />
                  <p className="text-xs font-normal text-[#232E3A] line-clamp-1">
                    {item?.name}
                  </p>
                </div>
                <p className="text-xs font-medium text-[#9747FF]">
                  {formatAmount(item?.value)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
