import formatDate from "@/helpers/format-date";
import { fetchBlob } from "@/utils/axiosWithoutData";
import { Button, DatePicker, Divider, Flex, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomModal from "./modal";

export default function ReportModalYearOrgan({
  openModalWithRange,
  onCancel,
  item,
}: any): JSX.Element {
  const [filter, setFilter] = useState<any>({});
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onDownload = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const { blob, headers } = await fetchBlob(item?.link, {
        ...filter,
        year: formatDate(filter?.year, "YYYY"),
        type: pathname === "/reports/het-reports" ? "het" : "hgt",
      });

      const contentDisposition = headers["content-disposition"];
      let filename = `report-${formatDate(new Date(), "YYYY-MM-DD")}.xlsx`;

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(
          /filename\*=UTF-8''(.+?)($|;)/,
        );

        if (filenameMatch != null && filenameMatch[1]) {
          // Decode the filename in case it uses URL-encoded UTF-8 characters
          filename = decodeURIComponent(filenameMatch[1]);
        } else {
          const fallbackMatch = contentDisposition.match(
            /filename="?(.+?)"?(;|$)/,
          );
          if (fallbackMatch != null && fallbackMatch[1]) {
            filename = fallbackMatch[1];
          }
        }
      }

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!openModalWithRange) {
      setFilter({});
    }
  }, [openModalWithRange]);

  const currentOrgType = JSON.parse(
    localStorage.getItem("user") || "{}",
  ).org_type;

  const orgOptions = () => {
    switch (currentOrgType) {
      case "het":
        return [
          { label: t("HET"), value: "1" },
          { label: t("UZI"), value: "3" },
          { label: t("Barchasi"), value: "all" },
        ];
      case "hgt":
        return [
          { label: t("HGT"), value: "2" },
          { label: t("UZI"), value: "3" },
          { label: t("Barchasi"), value: "all" },
        ];
      case "uzi":
        return [
          { label: t("HET"), value: "1" },
          { label: t("HGT"), value: "2" },
          { label: t("UZI"), value: "3" },
          { label: t("Barchasi"), value: "all" },
        ];
      default:
        return [];
    }
  };

  return (
    <CustomModal
      open={openModalWithRange}
      onCancel={onCancel}
      width={700}
      footer={
        <>
          <Divider className="mb-3" />
          <Flex className="items-center justify-end" gap={12}>
            <Button onClick={onCancel}>{t("cancel")}</Button>
            <Button
              loading={isLoading}
              onClick={onDownload}
              className="bg-[#40916C] text-white"
            >
              {t("download-doc")}
            </Button>
          </Flex>
        </>
      }
    >
      <Flex className="pt-[12px]" vertical>
        <Typography.Text className="text-lg font-medium text-wrap text-center mb-[24px]">
          {item?.title}
        </Typography.Text>

        <Flex className="gap-2 grid grid-cols-2">
          <Select
            id="sdf"
            placeholder={t("organization")}
            className="w-full"
            size="large"
            value={filter?.org}
            options={orgOptions()}
            onChange={(value) => {
              setFilter({ ...filter, org: value });
            }}
            allowClear
          />

          <DatePicker
            value={filter?.year}
            placeholder={t("year")}
            onChange={(e) => {
              setFilter({ ...filter, year: e });
            }}
            picker="year"
          />
        </Flex>
      </Flex>
    </CustomModal>
  );
}
