import arrowLeftIcon from "@/assets/arrow-left.svg";
import letterIcon from "@/assets/letter.svg";
import mapIcon from "@/assets/map.svg";
import phoneIcon from "@/assets/phone.svg";
import tickIcon from "@/assets/tick-square.svg";
import CustomModal from "@/components/modal";
import SlideTo from "@/components/slide-to";
import groups from "@/config/groups";
import { Info } from "@/features/acts-list";
import formatDate from "@/helpers/format-date";
import formatPhoneNumber from "@/helpers/format-phone-number";
import {
  Button,
  ConfigProvider,
  Divider,
  Flex,
  Image,
  Input,
  Layout,
  Result,
  Select,
  Skeleton,
  Tag,
} from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Map, Placemark, YMaps } from "react-yandex-maps";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import renderDeadline from "../../helpers/render-deadline";
import useOffenseState from "./state";
import { CloseCircleOutlined } from "@ant-design/icons";
import CashIcon from "@/features/acts-list/components/cash-icon";
import { useEffect, useRef } from "react";
import ReactPlayer from "react-player";

const { Header } = Layout;

export default function Offense(): React.ReactElement {
  const {
    Title,
    offense,
    user,
    t,
    translationMap,
    colorMap,
    status,
    iconMap,
    onImgClick,
    userGroup,
    btns,
    statuses,
    statusMutation,
    contextHolder,
    slideIndex,
    isCarouselModalOpen,
    handleCarouselModalCancel,
    renderFile,
    colorBgContainer,
    goBack,
    reason,
    setReason,
    note,
    setNote,
    regions,
    districts,
    region,
    setRegion,
    district,
    setDistrict,
    reasons,
    isRejectionModalOpen,
    onRejectionCancel,
    isApproveModalOpen,
    onApproveCancel,
    setIsRejectionModalOpen,
    setIsApproveModalOpen,
    acceptPersonMutation,
    acceptJuridicMutation,
    rejectOffenseMutation,
    isApprovePhycisModalOpen,
    onApprovePhysicCancel,
    setIsApprovePhycisModalOpen,
    regionPhysic,
    setRegionPhysic,
    inspectorPhysic,
    setInspectorPhysic,
    inspectorsPhysic,
    regionJuridic,
    setRegionJuridic,
    inspectorJuridic,
    setInspectorJuridic,
    inspectorsJuridic,
    isOpenMapModal,
    setIsOpenMapModal,
    onCloseMapModal,
    openModalImage,
    setOpenModalImage,
    handlePay,
  } = useOffenseState();

  const navigate = useNavigate();
  const videoRef = useRef();

  console.log("offense", offense);

  useEffect(() => {
    if (!openModalImage && videoRef.current) {
      // Pause the video and reset playback time
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [openModalImage]);

  return (
    <>
      {contextHolder}

      <Header
        style={{ background: colorBgContainer }}
        className="px-8 pt-2 flex items-center gap-4"
      >
        <button type="button" onClick={goBack}>
          <img src={arrowLeftIcon} alt="back" width={24} height={24} />
        </button>
        <h1 className="font-semibold text-2xl">{t("offense-details")}</h1>
      </Header>

      <div className="bg-white p-8">
        {typeof offense === "undefined" ? (
          <Skeleton
            active
            paragraph={{ rows: 20, width: "100%" }}
            className="mt-7"
          />
        ) : (
          <>
            {user.is_superuser ||
            user.groups.find(
              (g) => g.name === "superuser" || g.name === "admin",
            ) ? (
              <div className="border border-[#d7dde6] rounded-lg bg-[#fafbfc] p-4">
                <div className="flex justify-between items-center">
                  <div className="flex flex-col leading-[18px]">
                    <span className="font-medium text-base">
                      {typeof offense?.created_by?.passport_fio === "string" &&
                      offense?.created_by?.passport_fio.trim() !== ""
                        ? offense.created_by.passport_fio
                        : null}
                    </span>
                    <span className="text-[#40916c] text-sm">
                      {t("sender")}
                    </span>
                  </div>

                  {status === "accept" && (
                    <Button
                      className="text-[#FFFFFF] bg-[#3266D5] p-[8px_24px] h-auto hover:bg-[#EB5757/50]"
                      onClick={handlePay}
                    >
                      <CashIcon />
                      {t("pay")}
                    </Button>
                  )}

                  {/* {typeof offense?.created_by?.avatar === "string" &&
              offense?.created_by?.avatar.trim() !== "" ? (
                <img src={offense.created_by.avatar} alt="user" />
              ) : (
                <img src={userPlaceholderIcon} alt="user" />
              )} */}
                </div>

                <Divider className="my-5" />

                <div className="flex justify-between gap-16">
                  {typeof offense?.created_by?.phone === "string" &&
                  offense?.created_by?.phone.trim() !== "" ? (
                    <div className="flex items-center gap-2 text-sm leading-4">
                      <img src={phoneIcon} alt="phone" />
                      <span>
                        {formatPhoneNumber(
                          offense.created_by.phone,
                          "+### ## ### ## ##",
                        )}
                      </span>
                    </div>
                  ) : null}
                  {typeof offense?.created_by?.email === "string" &&
                  offense?.created_by?.email.trim() !== "" ? (
                    <div className="flex items-center gap-2 text-sm leading-4">
                      <img src={letterIcon} alt="letter" />
                      <span>{offense.created_by.email}</span>
                    </div>
                  ) : null}
                  {typeof offense?.created_by?.passport_address === "string" &&
                  offense?.created_by?.passport_address.trim() !== "" ? (
                    <div className="flex w-80 items-center gap-2 text-sm leading-4">
                      <img src={mapIcon} alt="map" />
                      <span>{offense.created_by.passport_address}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            <div className="my-4 border border-[#d7dde6] rounded-lg bg-[#fafbfc] leading-[56px]">
              <div className="flex items-center border-b border-b-[#d7dde6]">
                <span className="px-4 min-h-[56px] w-[320px]">
                  {t("offense-type")}
                </span>
                <span className="flex-1 px-4 min-h-[56px] border-l border-l-[#d7dde6]">
                  {offense?.violation_type?.name}
                </span>
              </div>

              <div className="flex items-center border-b border-b-[#d7dde6] bg-white">
                <span className="px-4 min-h-[56px] w-[320px]">
                  {t("status")}
                </span>
                <span className="flex-1 px-4 min-h-[56px] border-l border-l-[#d7dde6]">
                  <Tag color={colorMap[status]}>
                    {/* {t(translationMap[offense?.status?.key as Status])} */}
                    {t(translationMap[status])}
                  </Tag>
                  {typeof offense?.expiration !== "undefined"
                    ? renderDeadline(t, offense.expiration)
                    : null}
                </span>
              </div>

              <div className="flex items-center border-b border-b-[#d7dde6]">
                <span className="px-4 min-h-[56px] w-[320px]">
                  {t("application-date")}
                </span>
                <span className="flex-1 px-4 min-h-[56px] border-l border-l-[#d7dde6]">
                  {typeof offense?.created_at !== "undefined"
                    ? `${formatDate(
                        offense.created_at,
                        "DD.MM.YYYY HH:mm",
                      )} ${t("h")}`
                    : null}
                </span>
              </div>

              <div className="flex items-center border-b border-b-[#d7dde6] bg-white">
                <span className="px-4 min-h-[56px] w-[320px]">
                  {t("offender-info")}
                </span>
                <span className="flex-1 px-4 min-h-[56px] border-l border-l-[#d7dde6]">
                  {offense?.description}
                </span>
              </div>

              <div className="flex items-center border-b border-b-[#d7dde6]">
                <span className="px-4 min-h-[56px] w-[320px]">
                  {t("object-name")}
                </span>
                <span className="flex-1 px-4 min-h-[56px] border-l border-l-[#d7dde6]">
                  {offense?.object_name}
                </span>
              </div>

              {offense?.region?.name &&
                offense?.district?.name &&
                offense?.address && (
                  <div className="flex items-center border-b border-b-[#d7dde6] bg-white">
                    <span className="px-4 min-h-[56px] w-[320px] min-w-[320px]">
                      {t("address")}
                    </span>
                    <span className="px-4 min-h-[56px] border-l border-l-[#d7dde6]">
                      {offense?.region?.name ?? "-"},{" "}
                      {offense?.district?.name ?? "-"}, {offense?.address}
                    </span>
                    <Button
                      type="link"
                      onClick={() => {
                        setIsOpenMapModal(true);
                      }}
                    >
                      {t("view-on-map")}
                    </Button>
                  </div>
                )}

              <div className="flex items-center border-b border-b-[#d7dde6]">
                <span className="px-4 min-h-[56px] w-[320px] text-nowrap overflow-hidden">
                  {t("offense-datetime")}
                </span>
                <span className="flex-1 px-4 min-h-[56px] border-l border-l-[#d7dde6]">
                  {typeof offense?.act_date !== "undefined"
                    ? dayjs(offense.act_date).format(
                        `DD.MM.YYYY ${t("at")} HH:mm ${t("h")}`,
                      )
                    : null}
                </span>
              </div>

              <div className="flex items-center bg-white">
                <span className="px-4 min-h-[56px] w-[320px]">
                  {t("offense-photo-video")}
                </span>
                <span className="flex-1 flex items-center px-4 min-h-[56px] border-l border-l-[#d7dde6]">
                  {/* <div className="flex flex-wrap gap-2 py-2 leading-none">
                    {typeof offense?.files?.length === "number" &&
                    offense?.files?.length > 0 ? (
                      offense?.files.map((file, index) => (
                        <span
                          key={file.file}
                          className="flex gap-2 py-4 px-2 h-7 items-center bg-[#f0f3f4] rounded-md cursor-pointer"
                          onClick={() => {
                            onImgClick(index);
                          }}
                          aria-hidden
                        >
                          {file.file_type in iconMap ? (
                            <img
                              src={iconMap[file.file_type as FileType]}
                              alt="file"
                              width={18}
                            />
                          ) : null}
                          <span className="flex gap-1 text-xs leading-[18px]">
                            <p className="truncate max-w-[310px]">
                              {getFileData("filename", file.file)}
                            </p>
                            ({`${Math.round(file.file_size)} ${t("mb")}`})
                          </span>
                        </span>
                      ))
                    ) : (
                      <div className="w-full flex items-center justify-center border p-4 rounded-xl">
                        <div className="flex flex-col items-center gap-2">
                          <img src={folderIcon} alt="folder" width={24} />
                          <span className="text-[#62738C] font-normal text-xs w-28 text-center">
                            {t("no-files-up")}
                          </span>
                        </div>
                      </div>
                    )}
                  </div> */}

                  {offense?.files?.[0]?.file && (
                    <Button
                      type="link"
                      onClick={() => {
                        setOpenModalImage(true);
                      }}
                      className="!underline font-normal text-sm p-0"
                    >
                      {t("see")}
                    </Button>
                  )}
                </span>
              </div>
            </div>

            <div className="flex justify-between leading-[18px]">
              <Button
                className={clsx(
                  "rounded-lg text-[15px] border-[#e6eaf0] h-12 text-[#8498B4] flex items-center gap-2",
                  offense?.next_id === null
                    ? "cursor-not-allowed"
                    : "cursor-pointer",
                )}
                disabled={offense?.next_id === null}
                onClick={() => {
                  if (offense?.next_id !== null) {
                    // aha, so it throws an error when the button is clicked
                    // setOffenseId(offense.next_id);
                    navigate(`/offenses/${offense.next_id}`);
                  }
                }}
              >
                {t("next-application")}
                <img
                  className="-rotate-180"
                  src={arrowLeftIcon}
                  alt="arrow-right"
                />
              </Button>

              {/* offense?.status.key */}
              {(status === "under_consideration" || status === "overdued") &&
              userGroup?.name !== groups.superuser ? (
                <div className="flex gap-4">
                  <Button
                    type="ghost"
                    className="rounded-xl text-[15px] font-medium h-12 bg-[#f3f5f7]"
                    onClick={() => {
                      setIsRejectionModalOpen(true);
                    }}
                  >
                    {t("reject")}
                  </Button>

                  <ConfigProvider
                    theme={{ token: { colorPrimary: "#3266D5" } }}
                  >
                    <Button
                      type="primary"
                      className="rounded-xl text-[15px] font-medium h-12"
                      loading={acceptPersonMutation.isLoading}
                      onClick={() => {
                        setIsApprovePhycisModalOpen(true);
                        // acceptPersonMutation.mutate(offense.id);
                      }}
                    >
                      {t("accept-as-individual")}
                    </Button>
                  </ConfigProvider>

                  <Button
                    type="primary"
                    className="rounded-xl text-[15px] font-medium h-12 bg-[#40916C]"
                    onClick={() => {
                      setIsApproveModalOpen(true);
                    }}
                  >
                    {t("accept-as-legal-entity")}
                  </Button>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>

      <CustomModal
        title={null}
        open={isOpenMapModal}
        footer={null}
        onCancel={onCloseMapModal}
        centered
        width={500}
      >
        <Flex className="w-full h-[500px]">
          <YMaps>
            <Map
              defaultState={{
                center: [offense?.latitude, offense?.longitude],
                zoom: 16,
              }}
              height="100%"
              width="100%"
            >
              <Placemark geometry={[offense?.latitude, offense?.longitude]} />
            </Map>
          </YMaps>
        </Flex>
        <br />
        <div className="flex justify-end gap-4">
          <Button
            type="primary"
            size="large"
            className="bg-[#D8F3DC] text-[#40916C] w-[140px]"
            onClick={() => {
              setIsOpenMapModal(false);
            }}
          >
            {t("close")}
          </Button>
        </div>
      </CustomModal>

      <CustomModal
        title={<Title level={4}>{t("files")}</Title>}
        open={isCarouselModalOpen}
        footer={null}
        onCancel={handleCarouselModalCancel}
      >
        <Swiper navigation modules={[Navigation]} className="">
          <SlideTo slideIndex={slideIndex} />
          {offense?.files?.map((file) => (
            <SwiperSlide key={file.file}>{renderFile(file)}</SwiperSlide>
          ))}
        </Swiper>
      </CustomModal>

      <CustomModal
        open={false}
        onCancel={() => {
          //
        }}
        footer={null}
      >
        <Result
          status="success"
          title={t("success")}
          subTitle={t("legal-entity-accepted-successfully")}
          icon={
            <div className="h-24 w-24 bg-[#fafbfc] rounded-3xl m-auto flex">
              <img
                src={tickIcon}
                alt="no data"
                width={48}
                height={48}
                className="m-auto"
              />
            </div>
          }
          extra={
            <Button
              onClick={goBack}
              className="bg-[#D8F3DC] text-[#40916C] h-12 w-28 font-medium text-lg"
            >
              {t("close")}
            </Button>
          }
        />
      </CustomModal>

      <CustomModal
        title={null}
        open={openModalImage}
        footer={null}
        onCancel={() => {
          setOpenModalImage(false);
        }}
        centered
        width={500}
      >
        <Flex className="w-full max-h-[500px] min-h-max">
          <Flex className="w-full h-full">
            <Flex
              className={`grid gap-4 ${
                offense?.files?.length > 1 ? "grid-cols-2" : "grid-cols-1"
              } items-start`}
            >
              {offense?.files?.map((file) => {
                if (
                  file?.file?.split(".").pop() === "jpg" ||
                  file?.file?.split(".").pop() === "png"
                ) {
                  return (
                    <Flex key={file.file} className="h-[400px]">
                      <Image
                        src={file.file}
                        alt=""
                        preview
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Flex>
                  );
                }
                return (
                  <Flex key={file.file} className="h-[400px]">
                    <ReactPlayer
                      url={file.file}
                      key={file.file}
                      playing={openModalImage}
                      playsinline
                      controls
                      width="100%"
                      height="100%"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
        <br />
        <div className="flex justify-end gap-4">
          <Button
            type="primary"
            size="large"
            className="bg-[#D8F3DC] text-[#40916C] w-[140px]"
            onClick={() => {
              setOpenModalImage(null);
            }}
          >
            {t("close")}
          </Button>
        </div>
      </CustomModal>

      <CustomModal
        title={
          <Title level={4} className="text-center">
            {t("application-rejection")}
          </Title>
        }
        open={isRejectionModalOpen}
        footer={null}
        onCancel={onRejectionCancel}
        centered
        width={800}
      >
        <Info
          of={t("select-cancellation-reason")}
          className="flex flex-col gap-2"
          rootClassName="my-6"
        >
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  colorBgContainer: "#fafbfc",
                },
              },
            }}
          >
            <Select
              placeholder={t("reason")}
              options={reasons}
              value={reason}
              onChange={(val) => {
                setReason(val);
              }}
              allowClear
              className="bg-[#fafbfc]"
            />
          </ConfigProvider>
          <Input.TextArea
            rows={4}
            placeholder={t("note") ?? ""}
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            className="bg-[#fafbfc]"
          />
        </Info>

        <div className="flex justify-end gap-4">
          <Button
            type="text"
            size="large"
            className="bg-[#f3f5f7]"
            onClick={() => {
              setIsRejectionModalOpen(false);
            }}
          >
            {t("cancel")}
          </Button>

          <Button
            type="primary"
            size="large"
            loading={rejectOffenseMutation.isLoading}
            disabled={!reason}
            onClick={() => {
              rejectOffenseMutation.mutate({
                id: offense.id,
                description: note,
                reason,
              });
            }}
          >
            {t("approve")}
          </Button>
        </div>
      </CustomModal>

      <CustomModal
        title={
          <Title level={4} className="text-center">
            {t("accept-legal-entity")}
          </Title>
        }
        open={isApproveModalOpen}
        onCancel={onApproveCancel}
        footer={null}
      >
        <Info of="" className="flex flex-col gap-2" rootClassName="my-6">
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  colorBgContainer: "#fafbfc",
                },
              },
            }}
          >
            <Select
              placeholder={t("region")}
              options={regions}
              value={regionJuridic}
              onChange={(val) => {
                setRegionJuridic(val);
              }}
              className="bg-[#fafbfc]"
            />

            <Select
              placeholder={t("inspector")}
              options={inspectorsJuridic}
              value={inspectorJuridic}
              onChange={(val) => {
                setInspectorJuridic(val);
              }}
              className="bg-[#fafbfc]"
            />
          </ConfigProvider>
        </Info>

        <div className="flex justify-end gap-4">
          <Button
            type="text"
            size="large"
            className="bg-[#f3f5f7]"
            onClick={() => {
              setRegionJuridic(null);
              setInspectorJuridic(null);
              setIsApproveModalOpen(false);
            }}
          >
            {t("cancel")}
          </Button>

          <Button
            type="primary"
            size="large"
            loading={acceptJuridicMutation.isLoading}
            disabled={inspectorJuridic === null || regionJuridic === null}
            onClick={() => {
              acceptJuridicMutation.mutate({
                id: offense.id,
                inspector: inspectorJuridic,
                region: regionJuridic,
              });
            }}
          >
            {t("receive")}
          </Button>
        </div>
      </CustomModal>

      <CustomModal
        title={
          <Title level={4} className="text-center">
            {t("accept-individual")}
          </Title>
        }
        open={isApprovePhycisModalOpen}
        onCancel={onApprovePhysicCancel}
        footer={null}
      >
        <Info of="" className="flex flex-col gap-2" rootClassName="my-6">
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  colorBgContainer: "#fafbfc",
                },
              },
            }}
          >
            <Select
              placeholder={t("region")}
              options={regions}
              value={regionPhysic}
              onChange={(val) => {
                setRegionPhysic(val);
              }}
              className="bg-[#fafbfc]"
            />

            <Select
              placeholder={t("inspector")}
              options={inspectorsPhysic} // inspector
              value={inspectorPhysic}
              showSearch
              optionFilterProp="label"
              onChange={(val) => {
                setInspectorPhysic(val);
              }}
              className="bg-[#fafbfc]"
            />
          </ConfigProvider>
        </Info>

        <div className="flex justify-end gap-4">
          <Button
            type="text"
            size="large"
            className="bg-[#f3f5f7]"
            onClick={() => {
              setRegionPhysic(null);
              setInspectorPhysic(null);
              setIsApprovePhycisModalOpen(false);
            }}
          >
            {t("cancel")}
          </Button>

          <Button
            type="primary"
            size="large"
            loading={acceptJuridicMutation.isLoading}
            disabled={inspectorPhysic === null || regionPhysic === null}
            onClick={() => {
              acceptPersonMutation.mutate({
                id: offense.id,
                region: regionPhysic,
                inspector: inspectorPhysic,
              });
            }}
          >
            {t("receive")}
          </Button>
        </div>
      </CustomModal>
    </>
  );
}
