import regionsQuery from "@/features/dashboard/queries";
import formatDate from "@/helpers/format-date";
import { fetchBlob } from "@/utils/axiosWithoutData";
import { useQuery } from "@tanstack/react-query";
import { Button, DatePicker, Divider, Flex, Select, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomModal from "./modal";

export default function ReportModalWithRange({
  openModalWithRange,
  onCancel,
  item,
}: any): JSX.Element {
  const [filter, setFilter] = useState<any>({});
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const { data: locations, isFetching: isLocationsFetching } = useQuery({
    ...regionsQuery(),
    placeholderData: { count: 0, next: null, previous: null, results: [] },
  });

  const onDownload = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const { blob, headers } = await fetchBlob(item?.link, {
        ...filter,
        start_date: formatDate(filter?.start_date, "YYYY-MM-DD"),
        end_date: formatDate(filter?.end_date, "YYYY-MM-DD"),
        type: pathname === "/reports/het-reports" ? "het" : "hgt",
      });

      const contentDisposition = headers["content-disposition"];
      let filename = `report-${formatDate(new Date(), "YYYY-MM-DD")}.xlsx`;

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(
          /filename\*=UTF-8''(.+?)($|;)/,
        );

        if (filenameMatch != null && filenameMatch[1]) {
          // Decode the filename in case it uses URL-encoded UTF-8 characters
          filename = decodeURIComponent(filenameMatch[1]);
        } else {
          const fallbackMatch = contentDisposition.match(
            /filename="?(.+?)"?(;|$)/,
          );
          if (fallbackMatch != null && fallbackMatch[1]) {
            filename = fallbackMatch[1];
          }
        }
      }

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!openModalWithRange) {
      setFilter({});
    }
  }, [openModalWithRange]);

  const currentOrgType = JSON.parse(
    localStorage.getItem("user") || "{}",
  ).org_type;

  const orgOptions = () => {
    switch (currentOrgType) {
      case "het":
        return [
          { label: t("HET"), value: "1" },
          { label: t("UZI"), value: "3" },
          { label: t("Barchasi"), value: "all" },
        ];
      case "hgt":
        return [
          { label: t("HGT"), value: "2" },
          { label: t("UZI"), value: "3" },
          { label: t("Barchasi"), value: "all" },
        ];
      case "uzi":
        return [
          { label: t("HET"), value: "1" },
          { label: t("HGT"), value: "2" },
          { label: t("UZI"), value: "3" },
          { label: t("Barchasi"), value: "all" },
        ];
      default:
        return [];
    }
  };

  const currentRegionId = JSON.parse(
    localStorage.getItem("user") || "{}",
  ).region;

  useEffect(() => {
    if (currentRegionId) {
      setFilter({ ...filter, region_id: currentRegionId });
    }
  }, [currentRegionId])

  return (
    <CustomModal
      open={openModalWithRange}
      onCancel={onCancel}
      width={700}
      footer={
        <>
          <Divider className="mb-3" />
          <Flex className="items-center justify-end" gap={12}>
            <Button onClick={onCancel}>{t("cancel")}</Button>
            <Button
              loading={isLoading}
              onClick={onDownload}
              className="bg-[#40916C] text-white"
            >
              {t("download-doc")}
            </Button>
          </Flex>
        </>
      }
    >
      <Flex className="pt-[12px]" vertical>
        <Typography.Text className="text-lg font-medium text-wrap text-center mb-[24px]">
          {item?.title}
        </Typography.Text>

        <Flex className="gap-2 grid grid-cols-2">
          <Flex className="gap-2 grid grid-cols-2">
            <Select
              id="sdf"
              placeholder={t("region")}
              className="w-full"
              value={filter?.region_id}
              size="large"
              loading={isLocationsFetching}
              options={locations?.results?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              defaultValue={currentRegionId}
              disabled={currentRegionId !== 15}
              onChange={(value) => {
                setFilter({ ...filter, region_id: value });
              }}
              allowClear
            />
            <Select
              id="sdf"
              placeholder={t("organization")}
              className="w-full"
              size="large"
              value={filter?.org}
              options={orgOptions()}
              onChange={(value) => {
                setFilter({ ...filter, org: value });
              }}
              allowClear
            />
          </Flex>
          <Flex className="items-center gap-[4px]">
            <DatePicker
              size="large"
              className="w-full"
              placeholder={t("from")}
              value={filter?.start_date}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
              onChange={(date) => {
                setFilter({ ...filter, start_date: date });
              }}
            />
            -
            <DatePicker
              size="large"
              className="w-full"
              value={filter?.end_date}
              placeholder={t("to")}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
              onChange={(date) => {
                setFilter({ ...filter, end_date: date });
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </CustomModal>
  );
}
