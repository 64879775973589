/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/promise-function-async */
import type { CustomRoute } from "@/types";
import { lazy } from "react";

// ROUTE IMPORTS
import { actsListRoutes } from "@/features/acts-list";
import { authRoutesWith } from "@/features/auth";
import dashboarRoutes from "@/features/dashboard/routes";
import { inspectorsRoutes } from "@/features/inspectors";
import { notificationRoutes } from "@/features/notification";
import { violationRoutes } from "@/features/offenses";
import reportsRoutes from "@/features/reports/routes";
import { formsRoutes } from "@/features/tablet-forms";
import PdfGen from "@/views/pdf-gen";
import ProfileDelete from "@/views/profile-delete";
import SupportPage from "@/views/support";
import { sift } from "radash";
import permissionControlRoutes from "@/features/permission-control/routes";

// Global Pages
const Root = lazy(() => import("@/views/root"));
const NotFound = lazy(() => import("@/views/not-found"));
const Error = lazy(() => import("@/views/error"));
const isSuperUser = JSON.parse(
  localStorage.getItem("user" || "{}"),
)?.is_superuser;

const allPermissions =
  JSON.parse(
    localStorage.getItem("user") ?? "{}",
  )?.groups?.[0]?.permissions?.map((permission: any) => permission.codename) ??
  [];

const childrensWithPermissionCheck = (): any => {
  const childrens: any = [];

  if (isSuperUser) {
    const allRoutes = [
      dashboarRoutes,
      actsListRoutes,
      notificationRoutes,
      inspectorsRoutes,
      violationRoutes,
      formsRoutes,
      reportsRoutes,
      // permissionControlRoutes
    ];
    return sift(allRoutes);
  }

  if (allPermissions.length === 0 || allPermissions === undefined) {
    return childrens;
  }
  if (allPermissions.includes("view_dashboard")) {
    childrens.push(dashboarRoutes);
  }
  if (allPermissions.includes("view_violation")) {
    childrens.push(actsListRoutes);
  }
  if (allPermissions.includes("view_notifications")) {
    childrens.push(notificationRoutes);
  }
  if (allPermissions.includes("view_user")) {
    childrens.push(inspectorsRoutes);
  }
  if (allPermissions.includes("view_ek_violations")) {
    childrens.push(violationRoutes);
  }
  if (allPermissions.includes("view_inspector_act")) {
    childrens.push(formsRoutes);
  }
  if (
    allPermissions.includes("excel_report_activity") ||
    allPermissions.includes("excel_report_all") ||
    allPermissions.includes("excel_report_comparison") ||
    allPermissions.includes("excel_report_monthly") ||
    allPermissions.includes("excel_report_summary") ||
    allPermissions.includes("excel_report_violation")
  ) {
    childrens.push(reportsRoutes);
  }

  return childrens;
};

const routes: CustomRoute[] = [
  {
    id: "root",
    title: "E-Dalolatnoma",
    path: "/",
    element: <Root getRoutes={() => routes} />,
    errorElement: <Error getRoutes={() => routes} />,
    children: childrensWithPermissionCheck(),
  },
  authRoutesWith(() => routes),
  {
    id: "pdf-gen",
    title: "pdf-gen",
    path: "pdf-gen",
    element: <PdfGen />,
  },
  {
    id: "profile-delete",
    title: "profile-delete",
    path: "profile_delete",
    element: <ProfileDelete />,
  },
  {
    id: "global-not-found",
    title: "not-found",
    path: "*",
    element: <NotFound getRoutes={() => routes} />,
  },
  {
    id: "support",
    title: "support",
    path: "support",
    element: <SupportPage />,
  },
];

export default routes;
