import formatAmount from "@/helpers/format-amount";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

export default function AmountOfDamage({ data }: any): JSX.Element {
  const { t } = useTranslation();

  const send = useMemo(
    () => ({
      chart: {
        type: "pie",
        height: "100%",
      },
      labels: [t("mvd"), t("prosecutor-office")],
      series: [
        data.send_total_summ_emi ?? 0,
        data.send_total_summ_procroture ?? 0,
      ],
      colors: ["#37CD7B", "#FF5858", "#FFE071"],
      dataLabels: {
        enabled: false,
      },
      // tooltip: {
      //   enabled: true,
      //   y: {
      //     formatter: function (val: any) {
      //       return val.toLocaleString();
      //     },
      //   },
      // },
      tooltip: {
        enabled: true,
        theme: "light",
        style: {
          fontSize: "14px",
        },
        y: {
          formatter: function (val) {
            return val.toLocaleString();
          },
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const value = series[seriesIndex];
          const label = w.globals.labels[seriesIndex];
          return `<div class="custom-tooltip">${label}: ${formatAmount(
            value,
          )}</div>`;
        },
      },
      responsive: [
        {
          options: {
            chart: {
              width: 100,
            },
          },
        },
      ],
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "70%",
            labels: {
              show: true,
              total: {
                show: false,
                label: "Total",
              },
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "14px",
                color: undefined,
                offsetY: 5,
                formatter: function (val: any) {
                  return val.toLocaleString();
                },
              },
            },
          },
        },
      },
    }),
    [data],
  );

  const received = useMemo(
    () => ({
      chart: {
        type: "pie",
        width: "100%",
      },
      labels: [t("mvd"), t("prosecutor-office")],
      series: [
        data.received_total_sum_emi ?? 0,
        data.received_total_summ_procroture ?? 0,
      ],
      colors: ["#37CD7B", "#FF5858", "#FFE071"],
      dataLabels: {
        enabled: false,
      },
      // tooltip: {
      //   enabled: true,
      //   y: {
      //     formatter: function (val: any) {
      //       return val.toLocaleString();
      //     },
      //   },
      // },
      tooltip: {
        enabled: true,
        theme: "light",
        style: {
          fontSize: "14px",
        },
        y: {
          formatter: function (val) {
            return val.toLocaleString();
          },
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const value = series[seriesIndex];
          const label = w.globals.labels[seriesIndex];
          return `<div class="custom-tooltip">${label}: ${formatAmount(
            value,
          )}</div>`;
        },
      },
      legend: {
        show: false,
        floating: true,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "70%",
            labels: {
              show: true,
              total: {
                show: false,
                label: "Total",
              },
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "14px",
                color: undefined,
                offsetY: 5,
                formatter: function (val: any) {
                  return val.toLocaleString();
                },
              },
            },
          },
        },
      },
    }),
    [data],
  );

  return (
    <div className="flex flex-col p-4 h-full overflow-hidden">
      <div className="flex items-center justify-center mb-[12px] h-[22px] min-h-[22px] max-h-[22px]">
        <h4 className="text-left text-[14px] font-semibold font-Golos text-[#232E3A]">
          {t("amountOfDamaga")}
        </h4>
      </div>
      <div className="w-full grid grid-cols-2 gap-[24px] h-[calc(100%-34px)] min-h-[calc(100%-34px)] max-h-[calc(100%-34px)] overflow-hidden">
        <div className="flex flex-col items-center h-full w-full overflow-hidden">
          <div className="h-[calc(100%-67px)] max-h-[calc(100%-67px)] min-h-[calc(100%-67px)] overflow-hidden">
            <ReactApexChart
              options={{ ...send } as any}
              series={send.series}
              type="donut"
              width="100%"
              height="100%"
            />
          </div>
          <div className="w-full h-[45px] min-h-[45px] max-h-[45px]">
            <h5 className="mb-2 text-center text-[14px] font-medium leading-[14px] font-Golos text-[#0A0D2C]">
              {t("sentd")}
            </h5>
            <div className="flex flex-col gap-[6px] w-full">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span
                    style={{
                      borderColor: "#06D188",
                    }}
                    className="inline-block w-[8px] h-[8px] border-2 rounded-full "
                  />
                  <p className="text-xs font-normal text-[#232E3A]">
                    {t("mvd")}
                  </p>
                </div>
                <p className="text-xs font-medium text-[#9747FF]">
                  {formatAmount(data?.send_total_summ_emi ?? 0)}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span
                    style={{
                      borderColor: "#9747FF",
                    }}
                    className="inline-block w-[8px] h-[8px] border-2 rounded-full "
                  />
                  <p className="text-xs font-normal text-[#232E3A]">
                    {t("prosecutor-office")}
                  </p>
                </div>

                <p className="text-xs font-medium text-[#9747FF]">
                  {formatAmount(data?.send_total_summ_procroture ?? 0)}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center h-full w-full overflow-hidden">
          <div className="h-[calc(100%-67px)] max-h-[calc(100%-67px)] min-h-[calc(100%-67px)] overflow-hidden">
            <ReactApexChart
              options={{ ...received } as any}
              series={received.series}
              type="donut"
              width="100%"
              height="100%"
            />
          </div>
          <div className="w-full h-[45px] min-h-[45px] max-h-[45px]">
            <h5 className="mb-2 text-center text-[14px] font-medium leading-[14px] font-Golos text-[#0A0D2C]">
              {t("reached")}
            </h5>
            <div className="flex flex-col gap-[6px] w-full">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span
                    style={{
                      borderColor: "#06D188",
                    }}
                    className="inline-block w-[8px] h-[8px] border-2 rounded-full "
                  />
                  <p className="text-xs font-normal text-[#232E3A]">
                    {t("mvd")}
                  </p>
                </div>
                <p className="text-xs font-medium text-[#9747FF]">
                  {formatAmount(data?.received_total_sum_emi ?? 0)}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span
                    style={{
                      borderColor: "#9747FF",
                    }}
                    className="inline-block w-[8px] h-[8px] border-2 rounded-full "
                  />
                  <p className="text-xs font-normal text-[#232E3A]">
                    {t("prosecutor-office")}
                  </p>
                </div>

                <p className="text-xs font-medium text-[#9747FF]">
                  {formatAmount(data?.received_total_summ_procroture ?? 0)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
