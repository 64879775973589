/* eslint-disable @typescript-eslint/promise-function-async */
import canSee from "@/helpers/can-see";
import getUserClass from "@/helpers/get-user-class";
import { sift } from "radash";
import { lazy } from "react";
import hetReportsRoute from "../HETReports/routes";
import HgtReports from "./views/reports";

const Container = lazy(() => import("./views/container"));
const ReportsIcon = lazy(() => import("./components/report-icon"));

const currentOrgType = JSON.parse(
  localStorage.getItem("user") || "{}",
).org_type;

const isSuperUser = JSON.parse(localStorage.getItem('user' || "{}"))?.is_superuser

const reportsWithCheckingOrg = (): any => {
  if (isSuperUser === true) {
    return [
      {
        id: "hgt-reports",
        title: "hgt-reports",
        path: "hgt-reports",
        element: <HgtReports />,
      },
      hetReportsRoute,
    ]
  }

  switch (currentOrgType) {
    case "hgt":
      return sift([
        {
          id: "hgt-reports",
          title: "hgt-reports",
          path: "hgt-reports",
          element: <HgtReports />,
        },
      ]);
    case "het":
      return sift([hetReportsRoute]);
    case "uzi":
      return sift([
        {
          id: "hgt-reports",
          title: "hgt-reports",
          path: "hgt-reports",
          element: <HgtReports />,
        },
        hetReportsRoute,
      ]);
    default:
      return sift([]);
  }
};

const reportsRoutes = canSee(
  {
    id: "reports",
    title: "reports",
    path: "reports",
    Icon: ReportsIcon,
    children: reportsWithCheckingOrg(),
  },
  getUserClass().role,
);

export default reportsRoutes;
